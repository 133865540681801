import React from "react";
import { Link } from "react-router-dom";
import defaultImage from "../../imgs/interface/imgIcon.png";

import "../../css/products.css";

import favOn from "../../imgs/interface/favOn.gif";
import favOff from "../../imgs/interface/favOff.gif";

import { useGlobalContext } from "../../hooks/useGlobalContext";

import { useEffect, useState } from "react";
import { useNotify } from "../../hooks/useNotify";

import config from "../../config";

const ProductCard = React.forwardRef(({ products }, ref ) => {
  const [LoveIcon, setLoveIcon] = useState(favOff);
  const {
    favoris,
    favorisDispatch
  } = useGlobalContext();


  const [enStock, setEnStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { notify } = useNotify();

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
  }, [products]);

  const getImage = async () => {
    setIsLoading(true);
    if (products.Image) {
      setImage(products.Image);
    } else {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${products._id}`
      );
      try {
        const json = await response.json();
        if (response.ok) {
          const img = new Image();
          img.onload = async () => {
            const imageUrl = await getSignedURL(json.awsKey);

            setImage(imageUrl);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.log("Error loading image. Using default image.");
            setImage(defaultImage);
          };
          img.src = await getSignedURL(json.awsKey);
        } else {
          setImage(defaultImage);
        }
      } catch (error) {
        setImage(defaultImage);
      }
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };

  const handleClickFav = (e) => {
    e.preventDefault();

    const clickedFav = favoris.filter(
      (produit) => produit._id === products._id
    );

    if (clickedFav.length > 0) {
      favorisDispatch({ type: "DELETE_FAVORI", payload: products });
      notify("error", "Produit supprimé des favoris");
    } else {
      favorisDispatch({ type: "ADD_FAVORI", payload: products });
      notify("success", "Produit ajouté aux favoris");
    }
  };

  useEffect(() => {
    var stockLigne;
    if (products.Quantite > 1) {
      stockLigne = products.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      stockLigne = products.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [products.Quantite]);

  useEffect(() => {
    var activeFavory = favoris.filter(
      (produit) => produit._id === products._id
    );

    if (activeFavory.length > 0) {
      setLoveIcon(favOn);
    } else {
      setLoveIcon(favOff);
    }
  }, [favoris]);

  const productBody = (
    <>
    
     
      <Link className="noDecoration" to={`/Produits/${products._id}`}>
        <div className="card card-item">
          <div className="card-item-img" style={{border:"none"}}>
          {image && 
          <img src={image} alt="" style={{border:"none"}} />
          }
          </div>

          <div className="card-title productTitle text-start">
            {products.Number} - {products.Name}
          </div>

          <div className="card-title">
            <div className="disponibleCard">{enStock}</div>
            <div className="favoryBtn">
              <img
                src={LoveIcon}
                alt=""
                onClick={(e) => {
                  handleClickFav(e);
                }}
              />
            </div>
          </div>
        </div>
      </Link>
   
    
    </> 
  );

  const content = ref ? (
    <div className="col" ref={ref}>
      {productBody}
    </div>
  ) : (
    <div className="col">{productBody}</div>
  );

  return content ;
});
export default ProductCard;



/*
  const handleClickCart = (e) => {
    e.preventDefault();
    const product = {
      item: products,
      quant: 1,
    };

    if (products.Quantite > 0) {
      products.Quantite = products.Quantite - 1;
      //productsDispatch({ type: 'ADD_PRODUCT_TO_CART', payload: product })
      cartDispatch({ type: "ADD_TO_CART", payload: product });
      notify("success", "Produit ajouté au panier");
    } else {
      notify("error", "Plus de produit disponible.");
    }
  };
*/
