import { createContext } from 'react'

export const SearchContext = createContext()

export const searchReducer = (state, action) => {
    switch(action.type){
        case 'SET_RESULTS':
            return {
                searchResults: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'CLEAR_RESULTS':
            return {
                searchResults: action.payload
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
           
                
        default:
            return state
    }
}
