import { useEffect, useState } from "react";
import config from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useNotify } from "../../../hooks/useNotify";
import defaultImage from "../../../imgs/interface/no-image-icon.png";

const OrderRow = ({ product, index }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
  }, [product]);

  const getImage = async () => {
    setIsLoading(true);

    const response = await fetch(
      `${config.BASE_URL}/api/images/${product.produit._id}`
    );
    try {
      const json = await response.json();
      if (response.ok) {
        const img = new Image();
        img.onload = async () => {
          const imageUrl = await getSignedURL(json.awsKey);

          setImage(imageUrl);
          setIsLoading(false);
        };
        img.onerror = () => {
          console.log("Error loading image. Using default image.");
          setImage(defaultImage);
        };
        img.src = await getSignedURL(json.awsKey);
      } else {
        setImage(defaultImage);
      }
    } catch (error) {
      setImage(defaultImage);
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };

  // Conditionnez la classe CSS en fonction de l'index
  const rowClassName = "bg";

  return (
    <Link to={`/Produits/${product.produit._id}`} className="noDecoration">
    <div className={`order-row ${rowClassName}`}>
      <div style={{ display: "flex", alignItems:"center", marginLeft:"5px" }}>
        <div className="number">{index + 1}.</div>
        <div className="img">
          <img src={image} alt="" />
        </div>
        <div>
          <div className="product-info">
            <div className="product-number">{product.produit.Number}</div>
            <div className="product-name">{product.produit.Name}</div>
          </div>
        </div>

        
      </div>
      <div style={{marginRight:"5px"}}> {product.quant}</div>
    </div>
    </Link>
   
  );
};

export default OrderRow;
