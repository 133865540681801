import { createContext } from 'react'

export const ProductContext = createContext()

export const productsReducer = (productState, action) => {
    switch(action.type){
        case 'SET_PRODUCTS':
            return {
                products: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'ADD_PRODUCT_TO_CART':
                //Soustrait 1 de quantité
                action.payload.Quantite = action.payload.Quantite + 1

                
            return {
                
                productState
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   

        case 'CREATE_PRODUCT':
            return {
                products:[action.payload, ...productState.products]
            }
            case 'DELETE_PRODUCT':
                return {
                    products: productState.products.filter((p) => p._id !== action.payload._id)
                }
                
        default:
            return productState
    }
}
