import { Container } from "react-bootstrap";
import useProducts from "../../hooks/useProducts";
import ProductCard from "../../components/products/productCard";
import { useState, useEffect, useRef, useCallback } from "react";
import { BallTriangle } from "react-loader-spinner";

import "../../css/products.css"


const Products = ({ searchQuery, searching, setSearching }) => {
  
  
    const { isLoading, isError, error, products, hasNextPage, categoryPath, totalProducts } =
    useProducts({ searchQuery, searching });

  useEffect(() => {
    console.log("Searching this : ", searching);
    console.log("Search query this : ", searchQuery);
  }, []);


  const intObserver = useRef();

    

  const lastProductRef = useCallback(
    (product) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((products) => {
        if (products[0].isIntersecting && hasNextPage) {
          console.log("We are near the last product!!");
          setSearching({
            ...searching,
            page: searching.page + 1,
          });
        }
      });

      if (product) intObserver.current.observe(product);
    },
    [isLoading, hasNextPage]
  );



  if (isError) return <p>Error : {error.message}</p>;

  const content = products.map((product, i) => {
    if (products.length === i + 1) {
      return (
        <ProductCard
          ref={lastProductRef}
          key={product._id}
          products={product}
        />
      );
    }
    return <ProductCard key={product._id} products={product} />;
  });

  return (
    <Container
      fluid
      className="products-container"
      style={{ minHeight: "500px" }}
    >
      <div className="searchResume mt-3">
      {!isLoading && (
            <>
              <div className="title">{categoryPath}{searchQuery && ("/")}{searchQuery}</div>
              <div className="total">
                {totalProducts} {totalProducts >> 1 ? "produits" : "produit"} </div>
            </>
          )}
      </div>
      <div
        className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 produitResult"
        id="searchResult"
      >
        {content}
      </div>
      {!isLoading && (
        <div className="text-center mt-3">
        <button
          className="btn-primary"
          style={{ padding: "5px 10px 5px 10px", borderRadius: "4px" }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          retour au début
        </button>
      </div>
      )}
      

      {isLoading && (
        <div
          style={{
            display: "flex",
            height: "400px",
            paddingTop: "100px",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <BallTriangle
            height={200}
            width={200}
            radius={5}
            color="#0979be"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
    </Container>
  );
};

export default Products;
