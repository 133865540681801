import { createContext, useReducer } from 'react'

export const ProjectContext = createContext()


export const projectReducer = (state, action) => {
    switch(action.type){
        case 'SET_PROJECTS':
            return {
                projects: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'ADD_PROJECT':
                
                
            return {
                projects:[...state.projects, action.payload]
                
            }   
            case 'UPDATE_PROJECT':
                
                
            return {
                
                
            }   
        
        case 'DELETE_PROJECT':
            return {
                    projects: state.projects.filter((p) => p._id !== action.payload._id)
                }
                
        default:
            return state
    }
}