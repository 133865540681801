import mapPoint from '../imgs/interface/location.png'
import "../css/contact.css";
import Mapbox from '../components/system/mapBox'
import { useEffect } from 'react';



const Map = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        
      }, [  ])

    return (
        <div className="container-fluid">
            
                <div className="row">
                    <div className="col">
                        <div className="locationTitle">
                            <img src={mapPoint} />
                            <span>Comment nous trouver?</span>
                        </div>
                        <div className="mapBox">
                        <Mapbox />
                        </div>
                        <div className="horaire">
                            <h3>Horaire</h3>
                            <div>
                            Lundi au vendredi
                            </div>
                            <div>
                            9:00 à 12:00 / 13:00 à 17:00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

    )

}
export default Map