import { useState } from "react";
import iconMail from "../imgs/interface/email.png";

import emailjs from "@emailjs/browser";

import "../css/contact.css";

import { useEffect } from "react";
import { useNotify } from "../hooks/useNotify";

const Contact = ({ typeMessage }) => {
  //  Déclarations des états
  const [messageTitle, setMessageTitle] = useState();
  const [senderName, setSenderName] = useState();
  const [senderCompany, setSenderCompany] = useState();
  const [senderProject, setSenderProject] = useState();
  const [senderEmail, setSenderEmail] = useState();
  const [senderTel, setSenderTel] = useState();
  const [senderSubject, setSenderSubject] = useState();
  const [senderMessage, setSenderMessage] = useState();
  const [iconTitle, setIconTitle] = useState();

  const { notify } = useNotify();

  const handleSendMessage = async () => {
    if (!senderEmail) {
      notify("error", "veuillez inscrire une adresse courriel svp.");
      return;
    }
    if (!senderName) {
      notify("error", "veuillez inscrire votre nom svp.");
      return;
    }
    if (!senderMessage) {
      notify("error", "veuillez inscrire un message svp.");
      return;
    }
    const message = {
      titre: messageTitle,
      to: "info@medic-cine.com", // Change to your recipient
      from: senderName, // Change to your verified sender
      subject: senderSubject,
      reply_to: senderEmail,
      email: senderEmail,
      project: senderProject,
      company: senderCompany,
      tel: senderTel,
      html: senderMessage,
      messageTitle: messageTitle
    };
    emailjs
      .send("service_n2wbr4f", "template_j13rzkn", message, "L5zli32VXaN57kJkM")
      .then(
        function (response) {
          notify("success", "Votre message a été envoyer avec succès.");
          console.log("SUCCESS!", response.status, response.text);
          setSenderName("");
          setSenderCompany("");
          setSenderProject("");
          setSenderEmail("");
          setSenderTel("");
          setSenderSubject("");
          setSenderMessage("");
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    ///     Définir l'affichage

    setMessageTitle("Demande de renseignements");
    setIconTitle(iconMail);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      <div className="locationTitle">
        <img src={iconTitle} />
        <span>{messageTitle}</span>
      </div>
      <div className="row bigMargin">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control inputHeight"
              id="nomInput"
              onChange={(e) => setSenderName(e.target.value)}
              value={senderName}
              placeholder="Nom"
            />
            <label htmlFor="nomInput">Nom</label>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row soumissionRow">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="compagnie"
              placeholder="Compagnie"
              onChange={(e) => setSenderCompany(e.target.value)}
              value={senderCompany}
            />
            <label htmlFor="compagnie">Compagnie</label>
          </div>
        </div>
      </div>
      <div className="col-md-4"></div>
      <div className="row soumissionRow">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="projet"
              placeholder="Projet"
              onChange={(e) => setSenderProject(e.target.value)}
              value={senderProject}
            />
            <label htmlFor="projet">Projet</label>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row soumissionRow">
        <div className="col-md-4 texteLeft"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Courriel"
              onChange={(e) => setSenderEmail(e.target.value)}
              value={senderEmail}
              required // adding the required attribute
            />
            <label htmlFor="email">Courriel</label>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <div className="row soumissionRow">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="tel"
              placeholder="Projet"
              onChange={(e) => setSenderTel(e.target.value)}
              value={senderTel}
            />
            <label htmlFor="tel">Téléphone</label>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row soumissionRow">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="sujet"
              placeholder="Sujet"
              onChange={(e) => setSenderSubject(e.target.value)}
              value={senderSubject}
            />
            <label htmlFor="sujet">Sujet</label>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="row soumissionRow">
        <div className="col-md-4 texteLeft"></div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              id="message"
              style={{ height: "150px" }}
              placeholder="Description"
              onChange={(e) => setSenderMessage(e.target.value)}
              value={senderMessage}
            />
            <label htmlFor="message">Message</label>
          </div>
        </div>
      </div>
      <div className="row soumissionRow">
        <div className="col-md-4"></div>
        <div className="col-md-4 text-center">
          <div className="btn btn-lg btn-primary" onClick={handleSendMessage}>
            Envoyer la demande
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
