import { useGlobalContext } from '../hooks/useGlobalContext'
import { useState, useEffect } from 'react'

import ProduitCards from '../components/products/productCard'


const Favoris = () =>{

    const {favoris} = useGlobalContext()
    const [totalItem, settotalItem] = useState(null)
    const [totalItemsString, setTotalItemsString] = useState(null)
    
    
    const [isLoading, setIsLoading] = useState(true)

    


    useEffect(() => {
        //const favory =  localStorage.getItem('Favoris')
        if(favoris)
        {
            settotalItem(favoris.length) 
        }   
    }, [favoris])

    useEffect(()=>{
        if(totalItem > 0)
        {
            setTotalItemsString(totalItem + " produits")
        }else{
            setTotalItemsString(totalItem + " produit")
        }
        
    }, [totalItem])

    return (
        <div className="container-fluid">
            
                <h2 style={{ borderBottom:"solid thin #0979be", marginTop:"20px", paddingBottom:"10px"}}>Favoris <span style={{float:'right', fontSize:'18px', marginTop:'12px'}}>{totalItemsString}</span></h2>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6" id="searchResult">
                    {totalItem === 0 && <h3 style={{ marginTop: "12px", marginLeft: "40px" }}>- Aucun favori</h3>}
                    {favoris && favoris.map((products) => (
                        <ProduitCards key={products._id} products={products} setIsLoading={setIsLoading}/>
                    ))}
                </div>
            
        </div>
    )

}

export default Favoris