import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import "./commande.css";
import { Container } from "@mui/material";
import OrderInfo from "./components/infoOrder";
import OrderRow from "./components/orderRow";
import PDFGenerator from "./components/pdfGenerator";
import { BallTriangle } from "react-loader-spinner";
import { useSnackbar } from "../../context/snackbarContext"; // Adjust the import path as necessary

const OrderPage = () => {
  const { token } = useParams();
  const { showSnackbar } = useSnackbar();
  const [order, setOrder] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  
  const [canCancel, setCanCancel] = useState(false);

  const calculateTotalDays = (order) => {
    if (order?.dateDepart && order?.dateRetour) {
      const today = new Date();
      const startDate = new Date(order.dateDepart);
      const endDate = new Date(order.dateRetour);
      const timeDifference = endDate - startDate;
      if(startDate > today)
        {
          setCanCancel(true);
        }
      return Math.floor(timeDifference / (1000 * 3600 * 24));
    }
    return 0;
  };

  const fetchOrder = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/Commandes/client/${token}`);
      setOrder(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order:", error);
      setError("Failed to load order data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [token]);

  useEffect(() => {
    if (order) {
      setTotalDays(calculateTotalDays(order));
      const totalArticlesCount = order.Produits.reduce((sum, product) => sum + product.quant, 0);
      setTotalArticles(totalArticlesCount);
    }
  }, [order]);

  const approveOrder = async () => {
    try {
      const response = await axios.post(`${config.BASE_URL}/api/Commandes/approveOrder/${order._id}`);
      showSnackbar("Order approved successfully", "success");
      fetchOrder();
    } catch (error) {
      console.error("Error approving order:", error);
      showSnackbar("Failed to approve order", "error");
    }
  };

  const cancelOrder = async () => {
    try {
      const response = await axios.post(`${config.BASE_URL}/api/Commandes/cancelOrder/${order._id}`);
      showSnackbar("Commande annulé avec succès", "success");
      fetchOrder();
    } catch (error) {
      console.error("Error cancelling order:", error);
      showSnackbar("Failed to cancel order", "error");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          height: "400px",
          paddingTop: "100px",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <BallTriangle
          height={200}
          width={200}
          radius={5}
          color="#0979be"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="header-commande">
        <div>Commande {order.Numero}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {order.statut === "En attente" && (
            <div className="header-btn" onClick={approveOrder}>
              Approuver
            </div>
          )}
          {canCancel && order.statut !== "Annulé" && (
            <div className="header-btn" onClick={cancelOrder}>
              Annuler
            </div>
          )}
          {order.statut !== "Annulé" && (
          <div>
          <PDFGenerator order={order} totalDays={totalDays} />
        </div>  
            )}
          
        </div>
      </div>
      <Container>
        <OrderInfo order={order} totalDays={totalDays} />
        <div
          style={{
            marginTop: "15px",
            paddingBottom: "5px",
            marginBottom: "15px",
            borderBottom: "solid thin #232f3e",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Articles</div>
          <div>Quant</div>
        </div>
        {order.Produits?.map((product, index) => (
          <OrderRow key={product._id} index={index} product={product} />
        ))}
        <div style={{ textAlign: "right", marginRight: "15px" }}>
          Total : {totalArticles} articles
        </div>
      </Container>
    </div>
  );
};

export default OrderPage;
