import logo from "../../imgs/interface/logoSmall.png";
import "../../css/footer.css";
import Tel from "../../imgs/interface/tel.png";
import Ou from "../../imgs/interface/location.png";
import Email from "../../imgs/interface/email.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid">
      <footer>
        <div className="rights">
          <p>
            &copy; 2021 <img src={logo} className="logoBas" alt="" />, Inc. Tous droits réservés.
          </p>
        </div>
        <div className="contactMenu">
          <div className="contact">
            
            <img src={Tel} className="tel" alt="" />
           
            (514) 554-5243
       

          </div>
          <Link to="/Contact">
            <div className="btn Email">
              <img src={Email} alt="" />{" "}
            </div>
          </Link>
          <Link to="/Map">
            <div className="btn Location">
              <img src={Ou} alt="" />
            </div>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
