import { createContext } from 'react'

export const FavorisContext = createContext()

export const favorisReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FAVORIS':
           return {
                favoris: action.payload
            }
            case 'ADD_FAVORI':
                localStorage.setItem('Favoris', JSON.stringify([...state.favoris, action.payload]));
                return {
                    favoris: [...state.favoris, action.payload]
                };
            case 'DELETE_FAVORI':
                localStorage.setItem('Favoris', JSON.stringify(state.favoris.filter((c) => c._id !== action.payload._id)));
                return {
                    favoris: state.favoris.filter((c) => c._id !== action.payload._id)
                };
        default:
            return state
    }
}
