import { useState, useEffect } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import backBtn from "../../imgs/interface/back-Arrow-02.png";
import config from "../../config";
import "../../css/sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ products, searching, setSearching, setState, categoryTree }) => {
  const { user, tags, tagsDispatch } = useGlobalContext();
  const [collapsed, setCollapsed] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isSlideOut, setIsSlideOut] = useState(false);
  const [isSlideIn, setIsSlideIn] = useState(false);
  const [decorTagsWithProducts, setDecorTagsWithProducts] = useState([]);
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleCollapse = (categoryName) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  const handleSlideBack = () => {
    setIsSlideOut(false);
    setIsSlideIn(false);
  };

  const hasAssociatedProductsForTag = (tag) => {
    if (!products) return false;
    return products.some(
      (product) => product.Tags && product.Tags.includes(tag._id)
    );
  };

  const hasAssociatedProductsForCategory = (category) => {
    if (!products) return false;
    return products.some(
      (product) =>
        product.Categories.includes(category._id) ||
        (category.children && category.children.some((sub) => product.Categories.includes(sub._id)))
    );
  };

  const fetchTags = async () => {
    const response = await fetch(`${config.BASE_URL}/api/tags`);
    const json = await response.json();
    tagsDispatch({ type: "SET_TAGS", payload: json });
    setDecorTagsWithProducts(
      json.filter((tag) => tag.tagType === "Décor" && hasAssociatedProductsForTag(tag))
    );
  };

  useEffect(() => {
    if (!tags) fetchTags();
  }, [tags]);

  useEffect(() => {
    if (tags && products) {
      setDecorTagsWithProducts(
        tags.filter((tag) => tag.tagType === "Décor" && hasAssociatedProductsForTag(tag))
      );
    }
  }, [tags, products]);

  useEffect(() => {
    if (categoryTree && products) {
      setCategoriesWithProducts(
        categoryTree.filter((category) => hasAssociatedProductsForCategory(category))
      );
    }
  }, [categoryTree, products]);

  const handleTagClick = (tag) => {
    setSearching({ ...searching, page: 1, categorie: "Tous", decor: tag });
    setState({ isPaneOpenLeft: false });
    if (location.pathname !== '/Produits') {
      navigate('/Produits');
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    if (subcategory.children) {
      setSelectedSubcategory(subcategory);
      setIsSlideOut(true);
      setTimeout(() => setIsSlideIn(true), 200);
    }
  };

  const handleCategoryClick = (subcategory) => {
    setSearching({ ...searching, page: 1, categorie: subcategory, decor: "Tous" });
    setState({ isPaneOpenLeft: false });
    if (location.pathname !== '/Produits') {
      navigate('/Produits');
    }
  };

  return (
    <>
      <div className={`sidebar${isSlideOut ? " slide-out" : ""}`}>
        <div className="sidebar-title">
          <div className="title-text">Toutes les catégories</div>
        </div>

        {decorTagsWithProducts.length > 0 && (
          <div className="sidebar-section">
            <div className="section-title">Décors</div>
            {decorTagsWithProducts.slice(0, 4).map((tag) => (
              <div key={tag._id} className="section-link" onClick={() => handleTagClick(tag)}>
                {tag.Name}
              </div>
            ))}
            {decorTagsWithProducts.length > 4 && (
              <>
                <div className="collapse" id="decor-collapse">
                  {decorTagsWithProducts.slice(4).map((tag) => (
                    <div key={tag._id} className="section-link" onClick={() => handleTagClick(tag)}>
                      {tag.Name}
                    </div>
                  ))}
                </div>
                <div
                  className={`section-${collapsed["decor-collapse"] ? "moins" : "plus"}`}
                  onClick={() => toggleCollapse("decor-collapse")}
                  data-toggle="collapse"
                  data-target="#decor-collapse"
                  aria-expanded="false"
                  aria-controls="decor-collapse"
                >
                  {collapsed["decor-collapse"] ? "Voir moins" : "Voir plus"}
                </div>
              </>
            )}
          </div>
        )}

        {categoriesWithProducts.length > 0 &&
          categoriesWithProducts.map((category, index) => (
            <div className="sidebar-section" key={`category-${index}`}>
              <div className="section-title">{category.Name}</div>
              {category.children &&
                category.children.slice(0, 4).map((subcategory) => (
                  <div
                    className={`section-link${subcategory.children ? " subsubIcon" : ""}`}
                    key={`subcategory-${subcategory._id}`}
                    onClick={() => {
                      handleSubcategoryClick(subcategory);
                      handleCategoryClick(subcategory);
                    }}
                  >
                    {subcategory.Name}
                  </div>
                ))}
              {category.children && category.children.length > 4 && (
                <>
                  <div className="collapse" id={`collapse-${category.Name}`}>
                    {category.children.slice(4).map((subcategory) => (
                      <div
                        className={`section-link${subcategory.children ? " subsubIcon" : ""}`}
                        key={`collapsed-subcategory-${subcategory._id}`}
                        onClick={() => {
                          handleSubcategoryClick(subcategory);
                          handleCategoryClick(subcategory);
                        }}
                      >
                        {subcategory.Name}
                      </div>
                    ))}
                  </div>
                  <div
                    className={`section-${collapsed[category.Name] ? "moins" : "plus"}`}
                    onClick={() => toggleCollapse(category.Name)}
                    data-toggle="collapse"
                    data-target={`#collapse-${category.Name}`}
                    aria-expanded="false"
                    aria-controls={`collapse-${category.Name}`}
                  >
                    {collapsed[category.Name] ? "Voir moins" : "Voir plus"}
                  </div>
                </>
              )}
            </div>
          ))}
      </div>

      <div className={`sidebarSub${isSlideIn ? " slide-in" : ""}`}>
        <div className="sidebar-title" onClick={handleSlideBack}>
          <img src={backBtn} alt="Back Button" />
          <div className="title-text">Menu principale</div>
        </div>
        <div className="sidebar-section">
          {selectedSubcategory && (
            <>
              <div className="section-title ">{selectedSubcategory.Name}</div>
              {selectedSubcategory.children.map((category, index) => (
                <div
                  className="section-link"
                  key={`subsub${index}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.Name}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
