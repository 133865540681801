import { createContext } from 'react'

export const CompaniesContext = createContext()

export const companyReducer = (state, action) => {
    switch (action.type) {
        case 'SET_COMPANIES':
            return {
                companies: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'ADD_COMPANY':
            return {
                companies: [action.payload, ...state.companies]
            }
        case 'SAVE_COMPANY':
            
           // const selectActiveCompany = state.companies.filter((p) => p._id === action.payload._id)

           // console.log(selectActiveCompany)

            return {
                
                state
            }
        default:
            return state

    }
}
