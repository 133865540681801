/////////////////////////////
/////       HEADER      /////
/////////////////////////////
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { useGlobalContext } from "../../hooks/useGlobalContext";

import logo from "../../imgs/interface/logoSmall.png";
import config from "../../config";


import "../../css/header.css";
import "../../css/headerCategory.css";
import "../../css/searchBar.css";


const Header = ({
  searching,
  setSearching,
  setState,
  searchQuery,
  setSearchQuery,
}) => {

  const { categories, categoriesDispatch, favoris } = useGlobalContext();
  const [totalFav, setTotalFav] = useState();

  const [navbarOpen, setNavbarOpen] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();

  /// Creer la liste des categories
  const fetchCategories = async () => {
    const response = await fetch(`${config.BASE_URL}/api/categories`);
    const json = await response.json();
    categoriesDispatch({ type: "SET_CATEGORIES", payload: json });
  };

  useEffect(() => {
    if (!categories) {
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    setTotalFav(favoris.length);
  }, [favoris]);

  return (
    <header>
      <Navbar expand="lg" className="navHeader">
        <Container fluid>
          <Link to="/">
            <div className="logoTop">
              <img src={logo} alt="" />
            </div>
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <form
              className="searchForm"
              onSubmit={(e) => {
                e.preventDefault();
                setSearching({
                  ...searching,
                  page: 1,
                  categorie: "Tous",
                  decor: "Tous",
                });
                if (location.pathname !== "/Produits") {
                  navigate("/Produits");
                }
              }}
            >
              <NavDropdown
                title={
                  searching.categorie.Name
                    ? searching.categorie.Name
                    : searching.categorie
                }
                className="categorieSub"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={(e) => {
                    
                      setSearching({
                        ...searching,
                        page: 1,
                        categorie: "Tous",
                        decor: "Tous",
                      });
                      if (location.pathname !== "/Produits") {
                        navigate("/Produits");
                      }
                    
                  }}
                >
                  Tous
                </NavDropdown.Item>
                {categories &&
                  categories.map((category) => {
                    if (!category.parentId) {
                      return (
                        <NavDropdown.Item
                          key={category._id}
                          style={{ lineHeight: "25px" }}
                          onClick={(e) => {
                            
                              setSearching({
                                ...searching,
                                page: 1,
                                categorie: category,
                                decor: "Tous",
                              });
                              if (location.pathname !== "/Produits") {
                                navigate("/Produits");
                              }
                            
                          }}
                        >
                          {category.Name}
                        </NavDropdown.Item>
                      );
                    }
                  })}
              </NavDropdown>
             
                <input
                  className={searchQuery == "" ? "form-control searchText":"form-control searchText backgroundText" }
                  style={{
                    height: "100%",
                    width: "100%",
                    lineHeight: "40px",
                    borderRadius:"0px"
                  }}
                  type="search"
                  id="rechercher"
                  placeholder="Rechercher"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (e.target.value === "") {
                      setSearching({
                        ...searching,
                        page: 1,
                        categorie: "Tous",
                        decor: "Tous",
                      });
                      if (location.pathname !== "/Produits") {
                        navigate("/Produits");
                      }
                    }
                  }}
                />
                
              <button className="btn btn-secondary btnSearch" type="submit">
                Rechercher
              </button>
            </form>
          </Navbar.Collapse>

          <div className="menuRight">
            <Link to="/MesFavoris">
              <div className="btnFavory" title="Favoris">
                {totalFav}
              </div>
            </Link>

            <Navbar.Toggle
            title="Rechercher"
            className="custom-toggle2"
            aria-controls="basic-navbar-nav"
          />
          </div>
        </Container>
      </Navbar>

      <Navbar expand="lg" className="subHeader">
  <Container fluid>
    <Navbar.Toggle
      title="Menu catégorie"
      className="custom-toggle"
      aria-controls="basic-navbar-nav"
      onClick={() => setNavbarOpen(!navbarOpen)} // To toggle the Navbar open/close state
    />

    <Navbar.Collapse id="basic-navbar-nav" in={navbarOpen}> {/* Use the 'in' prop to control visibility */}
      <div className="subNav">
        <div
          className="panel-btn"
          title="Toutes les catégories"
          onClick={() => {
            setState({ isPaneOpenLeft: true });
            setNavbarOpen(false); // Close the Navbar when clicking "Toutes les catégories"
          }}
        />

        <div
          className={`catBtn ${searching.categorie === "Tous" ? "activeCat" : ""
            }`}
          style={{ lineHeight: "25px" }}
          onClick={(e) => {
            
              setSearching({
                ...searching,
                page: 1,
                categorie: "Tous",
                decor: "Tous",
              });
              if (location.pathname !== "/Produits") {
                navigate("/Produits");
              }
            
            setNavbarOpen(false); // Close the Navbar when clicking "Tous"
          }}
        >
          Tous
        </div>
        {categories &&
          categories.map((category) => {
            if (!category.parentId) {
              return (
                <div
                  className={`catBtn ${searching.categorie.Name === category.Name
                      ? "activeCat"
                      : ""
                    }`}
                  key={category._id + 1}
                  style={{ lineHeight: "25px" }}
                  onClick={(e) => {
                    
                      setSearching({
                        ...searching,
                        page: 1,
                        categorie: category,
                        decor: "Tous",
                      });
                      if (location.pathname !== "/Produits") {
                        navigate("/Produits");
                      }
                    
                    setNavbarOpen(false); // Close the Navbar when clicking a category
                  }}
                >
                  {category.Name}
                </div>
              );
            }
          })}
      </div>
    </Navbar.Collapse>
  </Container>
</Navbar>

    </header>
  );
};

export default Header;
