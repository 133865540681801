import { createContext } from 'react'

export const CartContext = createContext()

export const commandeReducer = (state, action) => {

    const deleteItem = state.cart_items.filter((c) => c.item._id === action.payload.item._id)

    switch (action.type) {
        case 'GET_CART_ITEMS':
            return {
                cart_items: action.payload
            }
        case 'ADD_TO_CART':

            const newItem = state.cart_items.filter((c) => c.item._id === action.payload.item._id)


            if (newItem && newItem.length > 0) {
                console.log("J'existe déja")
                console.log(newItem[0].quant)
                var newTotal = newItem[0].quant + 1
                newItem[0].quant = newTotal
                return {
                    //
                    cart_items: [...state.cart_items]
                }
            }
            else {
                return {
                    cart_items: [...state.cart_items, action.payload]

                }
            }
        //j'ajoute le payload

        case 'DELETE_CART_ITEM':


            if (deleteItem && deleteItem.length > 0) {
                if (deleteItem[0].quant > 1) {
                    deleteItem[0].quant = deleteItem[0].quant - 1
                    return {
                        //
                        cart_items: [...state.cart_items]
                    }
                }
                else {
                    return {
                        cart_items: state.cart_items.filter((c) => c.item._id !== action.payload.item._id)
                    }
                }
            }

        case 'DELETE_ALL_ITEM':
            console.log("Deleting item cart....")
            return {
                cart_items: state.cart_items.filter((c) => c.item._id !== action.payload.item._id)
            }

        case 'CLEAR_CART':
            return {
                cart_items: []
            }

        default:
            return state
    }
}
