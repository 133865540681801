import { createContext, useReducer } from 'react'
import { categoriesReducer } from './CategoryContext';
import { productsReducer } from './ProductContext';
import { tagsReducer } from './TagsContext';
import { fetchingReducer } from './fetchingContext';
import { searchReducer } from './searchContext';
import { favorisReducer } from './favoriContext';
import { cartReducer } from './cartContext';
import { commandeReducer } from './commandeContext';
import { userReducer } from './userContext';
import { useEffect } from 'react';
import { companyReducer } from './companyContext';
import { projectTypesReducer } from './projectTypesContext';
import { postesReducer } from './postesContext';
import { projectReducer } from './projectContext';



export const GlobalContext = createContext()


export const GlobalContextProvider = ({ children }) => {



    const [productsState, productsDispatch] = useReducer(productsReducer, {
      products: null  
    });
    const [categoriesState, categoriesDispatch] = useReducer(categoriesReducer, {
        categories: null  
      });
     
      const [tagsState, tagsDispatch] = useReducer(tagsReducer, {
        tags: null  
      });
      const [fetchingState, fetchingDispatch] = useReducer(fetchingReducer, {
        fetching: false  
      });
     
      const [resultsState, resultsDispatch] = useReducer(searchReducer, {
        searchResults: null  
      });
      const [favorisState, favorisDispatch] = useReducer(favorisReducer, {
        favoris: []
      });
      const [cartState, cartDispatch] = useReducer(cartReducer, {
        cart_items: [],
        startDate: null,
        endDate: null
      });
      const [commandeState, commandeDispatch] = useReducer(commandeReducer, {
        commande: null
      });
      const [projectState, projectDispatch] = useReducer(projectReducer, {
        projects: null,
      });
      const [userState, userDispatch] = useReducer(userReducer, {
        user: null
      });

      const [companyState, companyDispatch] = useReducer(companyReducer, {
        companies: null
      });
      const [projectTypesState, projectTypesDispatch] = useReducer(projectTypesReducer, {
        projectTypes: null
      });
      const [postesState, postesDispatch] = useReducer(postesReducer, {
        postes: null
      });


      useEffect(()=>{

        const user = JSON.parse(localStorage.getItem('user'))

        if(user){
          userDispatch({type:'LOGIN', payload: user})
        }
        
        const favoris = localStorage.getItem('Favoris');
        if (favoris) {
         // console.log(favoris)
          //favorisDispatch({ type: 'SET_FAVORIS', payload: JSON.parse(favoris) });
        }
        
    }, []);
        


    return (
        <GlobalContext.Provider value={{...projectState, projectDispatch, ...projectTypesState, projectTypesDispatch,...postesState, postesDispatch, ...companyState, companyDispatch, ...userState, userDispatch, ...commandeState, commandeDispatch, ...cartState, cartDispatch, ...favorisState, favorisDispatch, ...productsState, productsDispatch, ...categoriesState, categoriesDispatch, ...tagsState, tagsDispatch, ...fetchingState, fetchingDispatch, ...resultsState, resultsDispatch}}>
            { children }
        </GlobalContext.Provider>
    )

}