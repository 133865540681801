import { createContext } from 'react'

export const PostesContext = createContext()

export const postesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_POSTES':
            return {
                postes: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'ADD_POSTE':
            return {
                postes: [action.payload, ...state.postes]
            }
        default:
            return state
    }
}
