import React from "react";
//import mapboxgl from 'mapbox-gl'
import Map, { GeolocateControl, NavigationControl, Popup } from 'react-map-gl'
import { useState } from "react";
import "../../css/contact.css"
import 'mapbox-gl/dist/mapbox-gl.css';
import logo from '../../imgs/interface/logoSmall.png'






const Mapbox = () => {
    const [showPopup, setShowPopup] = useState(true)

    return(
    <Map
    mapboxAccessToken="pk.eyJ1IjoicG90YXJpdXMiLCJhIjoiY2w2ZmY5Y2twMDdnazNibzR5eXh5emFyYSJ9.435fgB7CEVCXqYRONM3kMA"
    style={{
        width:"100%",
        height:"600px",
        borderRadius:"15px",
    }}
    initialViewState={{
        longitude:-73.4927262,
        latitude:45.5447159,
        zoom:14,
    }}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    >
        
    
    {showPopup && (
      <Popup longitude={-73.4927262} latitude={45.5447159}
        anchor="bottom"
        closeButton={false}
        closeOnClick= {false}
        closeOnMove={false}
        onClose={() => setShowPopup(false)}>
        <div className="location">
        <img src={logo} alt="" />
        <div className="locationTitre">Nous sommes situé au :</div>
        <p>625 Rue du Parc-Industriel<br />
           Longueuil, Québec, Canada<br />
           J4H 3V7</p>
        </div>
      </Popup>)}
    <NavigationControl />
    <GeolocateControl />
    </Map>

    )
}

export default Mapbox