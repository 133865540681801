import { Link } from 'react-router-dom'


//  components
import logo from '../imgs/interface/logoBig.png'

import CleEnMain from '../imgs/interface/cle.png'
import Panier from '../imgs/interface/bigCart.png'
import Equipement from '../imgs/interface/equipement.jpg'
import Meubles from '../imgs/interface/meubles.jpg'
import Autres from '../imgs/interface/autres.jpg'

import '../css/home.css'


const Home = () => {


  return (
    <div className="home">
      <div className="container">
        <div className="px-4 py-5 my-5 text-center">
          <img className="homeLogo" src={logo} alt="" />
          <h1 className="display-5 fw-bold">Qui sommes-nous?</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">Nous sommes les spécialistes de la location d'équipements, de meubles et d'accessoires médicaux pour l'industrie du cinéma, de la télévision, du théâtre et des événements spéciaux au Québec.</p>

          </div>
        </div>
      </div>
      <hr className="featurette-divider"></hr>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2">
          <div className="col">
            <div className="px-4 py-5 my-5 text-center">
              <img className="homeImgs" src={CleEnMain} alt="" />
              <h2 className="fw-bold">Service clé en main.</h2>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">Médic-Ciné peut préparer pour vous l'équipement nécessaire pour un décor en fonction de vos besoins.</p>
                <div>
                  <Link to="/Contact">
                    <button type="button" className="btn btn-primary btn-lg">Demande de renseignement</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="px-4 py-5 my-5 text-center">
              <img className="homeImgs" src={Panier} alt="" />
              <h2 className="fw-bold">Préparer votre commande en ligne.</h2>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">Bientôt, vous aurez la possibilité de passer votre commande en ligne. Nous proposons un large choix de meubles et d'accessoires en ligne pour meubler votre plateau.</p>
                <div>
                  <Link to="/Produits">
                    <button type="button" className="btn btn-primary btn-lg">Magasiner maintenant</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="featurette-divider"></hr>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-md-10 mx-auto col-lg-5 imgCol">
            <img src={Equipement} className="featureImg" alt="" />
          </div>
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">Équipement médical. <span className="text-muted">Un choix remarquable.</span></h1>
            <p className="col-lg-10 fs-4">Nous avons un vaste choix d'équipement médical. Nous recevons de nouveaux arrivages toutes les semaines et nous n'avons pas toujours le temps d'ajouter les articles sur notre site. Si vous ne trouvez pas ce que vous cherchez, communiquez avec nous et il nous fera plaisir de vous aider.</p>

          </div>
        </div>
      </div>
      <hr className="featurette-divider"></hr>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">Meubles médicaux. <span className="text-muted">Bien remplis d'accessoires.</span></h1>
            <p className="col-lg-10 fs-4">Nous offrons le service clé en main qui vous permet de louer les meubles médicaux avec tous les accessoires.</p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5 imgCol">
            <img src={Meubles} className="featureImg" alt="" />
          </div>
        </div>
      </div>
      <hr className="featurette-divider"></hr>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-md-10 mx-auto col-lg-5 imgCol">
            <img src={Autres} className="featureImg" alt="" />
          </div>
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">Du médical! <span className="text-muted">Oui, mais bien plus encore.</span></h1>
            <p className="col-lg-10 fs-4">Nous possédons un inventaire de meubles et d'accessoires pour meubler un hôpital complet : corridors, chambres, bureaux de médecin, urgences, buanderies, etc.</p>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Home