import { createContext } from 'react'


export const FetchingContext = createContext()


export const fetchingReducer = (state, action) => {
    switch(action.type){
        case 'SET_FETCHING':
            return {
                fetching: true
            }
        case 'UNSET_FETCHING':
            return {
                fetching: false
            }   
        default:
            return state
    }
}