import { createContext } from 'react'

export const ProjectTypesContext = createContext()

export const projectTypesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PROJECT_TYPES':
            return {
                projectTypes: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'ADD_PROJECT_TYPE':
            return {
                projectTypes: [action.payload, ...state.projectTypes]
            }
            case 'DELETE_PROJECT_TYPE':
                return {
                projectTypes: [action.payload, ...state.companies]
                }
            
        default:
            return state
    }
}
